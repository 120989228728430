"use strict";

/* -------------------------------------------------------------------------

	PUNITED.viewport

	viewport切り替えのスクリプトを定義
	※jQuery不要
	Potential United inc.

	（利用方法）
	headのviewportの直後にviewport.jsを読み込む
	<meta name="viewport" content="width=1140">
	<script src="/assets/js/viewport.js"></script>

------------------------------------------------------------------------- */
// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.viewport) PUNITED.viewport = {};

(function () {
  // グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
  var ns = PUNITED.viewport;

  ns.switchViewport = function () {
    // viewportのcontentを幅で振り分け
    var viewportContent = window.screen.width > 679 ? "width=1200" : "width=device-width,initial-scale=1"; // 既存viewport削除

    var viewport = document.getElementsByName("viewport");

    if (viewport.length > 0) {
      for (var i = 0; i <= viewport.length; i++) {
        viewport[i].parentNode.removeChild(viewport[i]);
      }
    } // viewportセット


    var viewport = document.createElement("meta");
    viewport.setAttribute("name", "viewport");
    viewport.setAttribute("content", viewportContent);
    document.getElementsByTagName("head")[0].appendChild(viewport);
  };

  window.addEventListener('resize', function () {
    PUNITED.viewport.switchViewport();
  });
})();

PUNITED.viewport.switchViewport();